import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'gatsby'

import Layout from '../../layout/layout'

import Collaboration from '../../svg/retailer-collaboration.svg'
import Api from '../../svg/api.svg'

const Page = () => (
        <Layout>

            <Helmet>
                <title>Data en beveiliging</title>
                <meta name="description" content="Het Zupr platform ontsluit productinformatie richting de consument. Deze productinformatie is in de meeste gevallen direct afkomstig van de merken, maar soms ook rechtstreeks van retailers." />
            </Helmet>

            <section className="text-default">
                <div className="inner">
                    <div className="text">
                        <h2>Data en beveiliging</h2>
                        <p>Het Zupr platform bevat data van miljoenen producten, toont assortimentinformatie van duizenden (verkoop)locaties en verwerkt honderden orders per dag. Of het nou gaat om het bezorgen van producten, click & collect of reserveringen, al deze processen vereisen de verwerking van productdata, locatiedata en zo in bepaalde gevallen consumenten-data.</p>
                        <h3>En dit alles op een veilige manier!</h3>
                        <p>Binnen het platform wordt uiterst secuur en veilig omgegaan met data en wordt geen onnodige informatie gevraagd en/of opgeslagen. Zupr verwerkt alle data met in achtname van de AVG.</p>
                    </div>
                </div>
            </section>

            <section className="text-left grey image-bottom">
                <div className="inner">
                    <div className="text">
                        <h3>Hieronder een aantal kernwaarden over onze werkwijze:</h3>
                        <p>
                          <ul className="check-list">
                            <li>Enkel data in het publieke domein wordt ontsloten;</li>
                            <li>Prijs- en voorraadinformatie voor het tonen van het assortiment is optioneel;</li>
                            <li>Assortimentdata is en blijft eigendom van de locatie eigenaar;</li>
                            <li>Wachtwoorden voor beheer-accounts worden versleuteld opgeslagen en zijn niet opvraagbaar;</li>
                            <li>Zupr maakt bewust geen gebruik van een account voor consumenten, maar verwerkt de gegevens enkel binnen de scope van &eacute;&eacute;n transactie;</li>
                            <li>Conform onze cookiemelding wordt geen gebruikersinformatie getracked wanneer de cookiemelding niet geaccepteerd is.</li>
                          </ul>
                        </p>
                    </div>
                    <div className="image">
                        <Collaboration />
                    </div>
                </div>
            </section>

            <section className="text-default">
                <div className="inner">
                    <div className="text">
                        <h2>Entiteiten</h2>
                        <p>De drie belangrijkste type gebruikers in het platform zijn merken, (verkoop)locaties en consumenten. Voor elk van deze drie typen gelden specifieke spelregels.</p>
                        <h3>Merken</h3>
                        <p>De data-scope van Merken bevat product-informatie welke door het merk zelf aangeleverd is (geverifieerd) of producten welke geclaimd zijn door het merk. Het merk biedt deze informatie ter beschikking als dienst richting haar verkooplocaties en als informatie richting de consument. Correcte goed beheerde productdata is een belangrijk argument in het aankoopproces van consumenten. Daarnaast wordt aan een merk ge-anonomiseerde data over zoekgedrag van consumenten, dekking van verkooplocaties en andere BI-data aangeboden. Tot slot heeft het merk ook inzicht in welke verkooplocaties producten van een bepaald merk verkopen.</p>
                        <h3>Consumenten</h3>
                        <p>Zoals in de kernwaarden al toegelicht maakt Zupr bewust geen gebruik van een consumenten-account, waardoor de informatie van een consument enkel relevant is binnen een heel specifieke context en scope. Er wordt dus geen onnodige data opgeslagen of beschikbaar gesteld buiten deze context. Daarnaast maakt Zupr gebruik van Google analytics conform een drie-laagse cookiemelding. Google is verantwoordelijk voor het verwerken van deze data en hiermee voldoet Zupr dus volledig aan de wet-en-regelgeving op het gebied van de AVG.</p>
                        <h3>(Verkoop)Locaties</h3>
                        <p>Als spil in het web brengt uiteindelijk de data van retailers / verkooplocaties alles bij elkaar. Het 'product-locatie' data-punt is hier het meest duidelijke voorbeeld van. Welk product wordt op welke (verkoop)locatie aangeboden. Deze data is in principe publiek, aangezien dit een representatie is van het assortiment van de locatie. De retailer kan desgewenst deze data verrijken met prijs en voorraadinformatie, maar dit is niet vereist voor de werking van het platform.</p>
                        <p><a rel="noopener noreferrer" className="btn btn-demo" href="/nl/algemene-voorwaarden/">Bekijk hier onze algemene voorwaarden</a></p>
                        <p><a rel="noopener noreferrer" className="btn btn-demo" href="/nl/gebruiksvoorwaarden/">Bekijk hier onze gebruikersvoorwaarden</a></p>
                    </div>
                </div>
            </section>

            <section className="text-left">
                <div className="inner">
                    <div className="text">
                        <h2>Software en testen</h2>
                        <p>De in-house ontwikkelde software van Zupr draait op een beveiligd redundant en ge-load-balanced server-cluster welke periodiek getest wordt door ervaren beveiliging-experts.</p>
                        <p>Het Zupr platform is geimplementeerd als een API (application programming interface) waar onder andere zupr.nl, de beheer-app voor Retailers en de diverse winkelgebieden-pagina's gebruik van maken. Omdat het uitgangspunt een API is wordt er bij Zupr continue goed nagedacht over welke data toegankelijk is voor welk soort gebruiker en de noodzakelijke autorisatie om deze data te mogen manipuleren.</p>
                    </div>
                    <div className="image">
                        <Api />
                    </div>
                </div>
            </section>

            <section className="contact-boost">
                <div className="inner">
                    <h2>Heeft u vragen?</h2>
                    <p>Voor vragen of toelichting betreffende de data en/of beveiliging van het Zupr platform kunt u contact opnemen met het Zupr team.</p>
                    <p>
                        <a href="mailto:support@zupr.nl?subject=Data en beveiliging binnen Zupr" className="email">support@zupr.nl</a>
                        <a href="tel:+31854011300" className="telephone">+31 (0)85 401 13 00</a>
                    </p>
                </div>
            </section>

    </Layout>
)

export default Page
